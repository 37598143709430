<template>
	<!-- 步骤一 -->
	<div class="stepOne-page-all">
		<div class="stepOne-pageTitle" v-show="this.resId==2">个人讲师入驻</div>
		<div class="stepOne-pageTitle" v-show="this.resId==1">机构入驻</div>
		<div class="stepOne-pageLine"></div>
		<!-- 标签 -->
		<stepitem type="1"></stepitem>
		<!-- 正文 -->
		<div class="stepOne-pageContent">
			<div class="stepOne-contentText">
				<div style="margin-bottom: 30px;">{{data.title}}</div>
				<div style="margin-bottom: 30px;">导言</div>
				<div style="margin-bottom: 20px;">{{data.content}}</div>
			</div>
			<div class="stepOne-pageLine"></div>
			<div class="stepOne-pageAgree">
				<el-checkbox v-model="checked" label="我已阅读并同意此协议" color="#00D0B0" class="stepOne-agreeCheck"></el-checkbox>
				<img src="../../../assets/img/organize/tip.png" class="stepOne-agreeIcon">
				<span class="stepOne-agreeText">阅读完整个协议，才能进行下一步哦。</span>
			</div>
		</div>
		<!-- 下一步按钮 -->
		<div class="stepOne-pageButton" @click="toNext()">下一步</div>
		<div class="stepOne-zhezhao" v-show="this.checked==false"></div>
	</div>
</template>

<script>
	import stepitem from '../../common/stepitem.vue';
	export default {
		data() {
			return {
				// 接收到的id
				resId: "",
				checked: false,
				// 获取到的总数据
				data: {},
			};
		},
		components: {
			stepitem
		},
		created() {
			this.resId = this.$route.query.id
			this.getData()
		},
		methods: {
			// 获取协议内容
			getData() {
				this.$get('index/getprotocol', {
					type: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.data = response.data.protocol;
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			// 下一步按钮
			toNext() {
				if (this.resId == 2) {
					this.$router.push({
						path: '/stepTwoPerson',
						query: {
							id: 2
						}
					})
				} else {
					this.$router.push({
						path: '/stepTwoOrganize',
						query: {
							id: 1
						}
					})
				}
			},

		}
	};
</script>

<style>
	.stepOne-page-all {
		width: 55%;
		height: 920px;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		overflow: hidden;
	}

	.stepOne-pageTitle {
		width: 100%;
		height: 130px;
		font-size: 48px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #151515;
		line-height: 130px;
		text-align: center;
	}

	.stepOne-pageLine {
		width: 100%;
		height: 1px;
		background: #E6E6E6;
	}

	.stepOne-pageStep {
		width: 100%;
		height: 120px;
		margin-top: 30px;
		display: flex;
		flex: 3;
		flex-wrap: nowrap;
	}

	.stepOne-pageContent {
		width: 850px;
		height: 530px;
		border: 1px solid #CCCCCC;
		margin: 0 auto;
	}

	.stepOne-contentText {
		width: 800px;
		height: 447px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		margin: 0 auto;
		margin-top: 20px;
		overflow: hidden;
		overflow-y: auto;
	}

	.stepOne-pageAgree {
		width: 100%;
		height: 16px;
		margin-left: 20px;
		margin-top: 19px;
	}

	.stepOne-agreeCheck {
		float: left;
	}

	.stepOne-agreeIcon {
		float: left;
		margin-left: 58px;
		width: 20px;
		height: 20px;
	}

	.stepOne-agreeText {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		line-height: 16px;
		margin-left: 10px;
	}

	.stepOne-pageButton {
		width: 146px;
		height: 54px;
		background: #00D0B0;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		float: right;
		margin-right: 30px;
		margin-top: 20px;
		cursor: pointer;
	}

	.stepOne-zhezhao {
		width: 146px;
		height: 54px;
		background-color: #333333;
		opacity: 0.3;
		position: absolute;
		margin-top: 20px;
		margin-left: 880px;
		cursor: pointer;
	}

	.stepOne-biaoqian2 {
		margin-left: -5px;
		position: absolute;
		z-index: 2;
		width: 343px;
		height: auto;
	}

	.stepOne-biaoqian1 {
		position: absolute;
		z-index: 1;
		width: 343px;
		height: auto;
	}
</style>
