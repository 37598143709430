<template>
	<div class="pageStep">
		<div :class="['stepItem', type==1 ? 'stepItem stepItemchoose':'']">
			<div class="stepText">
				<div :class="['stepTextNum', type==1 ? 'stepTextNum stepTextNum1':'']">1</div>
				<div class="stepTextContent">签订入驻协议</div>
			</div>
		</div>
		<div :class="['stepItem1', type==2 ? 'stepItem1 stepItemchoose':'']" >
			<div class="stepText">
				<div :class="['stepTextNum', type==2 ? 'stepTextNum stepTextNum1':'']">2</div>
				<div class="stepTextContent1">填写并提交资料</div>
			</div>
		</div>
		<div :class="['stepItem3', type==3 ? 'stepItem3 stepItemchoose mtop5':'']">
			<div class="stepText">
				<div :class="['stepTextNum', type==3 ? 'stepTextNum stepTextNum1':'']">3</div>
				<div class="stepTextContent1">等待审核</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			};
		},
		props: {
			type: String
		},
	};
</script>
<style scoped>
	.pageStep {
		width: 100% !important;
		height: 81px !important;
		display: flex;
		margin-top: 27px !important;
		margin-bottom: 37px;
	}

	.stepItem {
		width: 38%;
		height: 81px;
		font-size: 20px;
		position: relative;
		margin-left: -10px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		background: url(../../assets/img/organize/biaoqian1.png);
		background-size: 100% 100%;
		color: #00D0B0;
		display: flex;
		z-index: 200;
		justify-items: center;
		align-items: center;
	}
	.stepItem1{
		width: 38%;
		height: 81px;
		font-size: 20px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		background: url(../../assets/img/organize/biaoqian1.png);
		background-size: 100% 100%;
		color: #00D0B0;
		display: flex;
		z-index: 100;
		position: relative;
		margin-left: -35px;
		justify-items: center;
		align-items: center;
	}
	.stepItem3{
		width: 36%;
		height: 75px;
		margin-top: 3px;
		position: relative;
		margin-right: -30px;
		font-size: 20px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		background: url(../../assets/img/organize/biaoqian3.png);
		background-size: 100% 100%;
		color: #00D0B0;
		display: flex;
		margin-left: -35px;
		justify-items: center;
		align-items: center;
	}
	.stepItemchoose {
		background: url(../../assets/img/organize/biaoqian2.png);
		background-size: 100% 100%;
		color: #FFFFFF;
		height: 81px !important;
	}

	.stepText {
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	.stepTextNum {
		width: 40px;
		height: 40px;
		border: 2px solid #00D0B0;
		border-radius: 50%;
		text-align: center;
		font-size: 30px;
		line-height: 40px;
		margin-right: 15px;
	}

	.stepTextNum1 {
		width: 40px;
		height: 40px;
		border: 2px solid #FFFFFF;
		border-radius: 50%;
		text-align: center;
		line-height: 40px;
		font-size: 30px;
		margin-right: 15px;
	}
	.mtop5{
		margin-top: 0px !important;
	}
</style>
